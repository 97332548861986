import { isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import scrollama from 'scrollama';
import './index.css';

interface Props {
  isLegendDisplayed?: boolean;
  isStatic: boolean;
  multipleYxes?: boolean;
  category: string[];
  indicatorData: {
    label: string;
    color: string;
    data: number[];
  }[];
  noFill?: boolean;
  standardVal?: number;
  standardLabel?: string;
  workHoursFilter?: number[][];
  colourBar?: string[][] | string[][][];
  labelText?: string | string[];
  highLow?: number[];
  minYStart?: number;
  maxYEnd?: number;
  maxTicksLimit?: number;
  summary?: {
    min: number;
    max: number;
    maxIdx: number[];
    minIdx: number[];
  };
  spanGaps?: boolean;
  showHealthRating?: boolean;
}

class LineChart extends React.Component<Props> {
  chart!: Line;
  scroller = scrollama();

  componentDidMount() {
    this.updateLegend();
  }

  componentDidUpdate() {
    this.updateLegend();
  }

  updateLegend() {
    if (this.chart) {
      const legendContainer = document.getElementById('chartjs-html-legend');
      if (legendContainer) {
        legendContainer.innerHTML = this.chart.chartInstance.generateLegend();
      }
    }
  }

  shouldComponentUpdate(nextProps: any) {
    return !isEqual(this.props, nextProps);
  }

  genGradientArea = (ctx, color, h) => {
    const gradientColor = ctx.createLinearGradient(0, 0, 0, h);
    gradientColor.addColorStop(0, color);
    gradientColor.addColorStop(1, '#fff');
    return gradientColor;
  };

  chartData = canvas => {
    const ctx = canvas.getContext('2d');
    const { indicatorData, multipleYxes, noFill } = this.props;
    const datasets = indicatorData.map((item, index) => {
      return {
        label: item.label,
        fill: noFill,
        yAxisID: multipleYxes ? `y-axis-${index}` : 'y-axis-0',
        lineTension: 0.5,
        // 数据为null时，不渲染此处线条，出现缺口
        spanGaps: false,
        backgroundColor: !noFill
          ? this.genGradientArea(ctx, item.color, 500)
          : 'transparent',
        borderColor: item.color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointRadius: 0,
        pointBorderWidth: 1,
        pointBorderColor: '#fff',
        pointBackgroundColor: item.color,
        data: item.data
      };
    });

    return {
      labels: this.props.category,
      datasets
    };
  };

  chartOptions = () => {
    const {
      highLow,
      summary,
      labelText,
      colourBar,
      standardVal,
      standardLabel,
      minYStart,
      maxYEnd,
      category,
      maxTicksLimit,
      multipleYxes,
      workHoursFilter,
      isStatic,
      showHealthRating
    } = this.props;

    const isMobile = window.innerWidth <= 576;

    let bill = {
      multipleYxes,
      colourBar,
      standardVal,
      standardLabel,
      range: workHoursFilter,
      showHealthRating
    };

    if (summary && highLow) {
      bill = Object.assign({}, bill, {
        high: {
          idx: summary.maxIdx,
          value: summary.max,
          badge: highLow[0]
        },
        low: {
          idx: summary.minIdx,
          value: summary.min,
          badge: highLow[1]
        }
      });
    }

    const xAxesLabelOffset = 0;
    const firstDate = moment(category[0]);
    const lastDate = moment(category[category.length - 1]);
    const daysDiff = lastDate.diff(firstDate, 'days');

    let time = {
      unit: 'hour',
      displayFormats: {
        minute: 'HH:mm',
        hour: 'HH:mm',
        day: 'MM/D'
      }
    };

    if (daysDiff >= 2) {
      let unit = 'day';
      if (daysDiff > 14) {
        unit = 'week';
      }
      if (daysDiff > 150) {
        unit = 'month';
      }
      time = Object.assign(time, {
        unit,
        displayFormats: {
          minute: 'HH:mm',
          hour: 'HH:mm',
          day: 'MM/D',
          week: 'MMM DD',
          month: 'MMM'
        },
        stepSize: 1
        // min: '03-12 08:00',
        // max: '03-17 17:00'
      });
      if (this.chart) {
        const mychart = this.chart.chartInstance;
        mychart.options.scales.xAxes[0].ticks.minor.labelOffset = xAxesLabelOffset;
        mychart.update();
      }
    }

    let tickYOp = {
      fontSize: 14,
      fontFamily: 'Nunito',
      padding: 20,
      fontColor: '#999',
      min: minYStart,
      max: maxYEnd,
      maxTicksLimit
    };

    if (maxYEnd && maxTicksLimit) {
      tickYOp = Object.assign({}, tickYOp, {
        stepSize: maxYEnd / maxTicksLimit
      });
    }

    const yAxes = [
      {
        type: 'linear',
        ticks: tickYOp,
        id: 'y-axis-0',
        position: 'left',
        gridLines: {
          color: workHoursFilter && workHoursFilter.length ? '#999' : '#efefef',
          drawTicks: false
          // display: false
        }
      }
    ];

    if (multipleYxes) {
      yAxes.push({
        type: 'linear',
        ticks: tickYOp,
        id: 'y-axis-1',
        position: 'right',
        gridLines: {
          color: workHoursFilter && workHoursFilter.length ? '#999' : '#efefef',
          drawTicks: false
          // display: false
        }
      });
    }

    return {
      plugins: {
        datalabels: false,
        addArrowLabel: {
          label: labelText
        },
        bill,
        doughnut: false
      },
      animation: false,
      events: isStatic ? [] : ['mousemove'],
      legend: {
        display: false,
        position: 'top',
        align: 'center'
      },
      tooltips: {
        intersect: false,
        enabled: false,
        mode: 'index',
        position: 'nearest',
        custom: tooltip => {
          // Tooltip Element
          const canvasEl = this.chart.chartInstance.canvas;
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            if (canvasEl && canvasEl.parentNode) {
              canvasEl.parentNode.appendChild(tooltipEl);

              this.scroller
                .setup({
                  step: '.graph-content',
                  offset: 0.2
                })
                .onStepEnter(() => {
                  tooltipEl!.classList.add('is-fixed');
                })
                .onStepExit(() => {
                  tooltipEl!.classList.remove('is-fixed');
                });

              canvasEl.parentNode.addEventListener('mouseleave', () => {
                if (tooltipEl) {
                  tooltipEl.style.opacity = '0';
                }
              });
            }
          }


          if (
            (
              document.querySelector('.kiosk-page--content-modal') ||
              document.body
            ).scrollHeight -
            (window.innerHeight || document.documentElement.clientHeight) <
            200
          ) {
            tooltipEl!.classList.remove('is-fixed');
          } else {
            tooltipEl!.classList.add('is-fixed');
          }
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }
          // Set Text
          if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(bodyItem => {
              return bodyItem.lines;
            });
            let innerHtml = '<thead>';
            titleLines.forEach(title => {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';
            const parsedBodyLines = bodyLines.map((item, i) => {
              return {
                text: item[0],
                colors: tooltip.labelColors[i],
                value: Number(item[0].split(':')[1])
              }
            })
            parsedBodyLines.sort((a, b) => {
              return b.value - a.value;
            })
            parsedBodyLines.forEach(body => {
              const colors = body.colors;
              let style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 2px';
              const span =
                '<span class=\'chartjs-tooltip-key\' style=\'' +
                style +
                '\'></span>';
              innerHtml += '<tr><td>' + span + body.text + '</td></tr>';
            });
            innerHtml += '</tbody>';
            const tableRoot = tooltipEl.querySelector('table');
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }
          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';

          let left = tooltip.caretX + 'px';
          let top = (tooltip.caretY - 40) + 'px';
          // Adjust tooltip position if near right edge
          const ttpPosX = tooltip.caretX + tooltipEl.offsetWidth
          const canvasWidth = canvasEl.getBoundingClientRect().width
          const ttpPosY = (tooltip.caretY - 40) + tooltipEl.offsetHeight
          const canvasHeight = canvasEl.getBoundingClientRect().height

          if (ttpPosX > canvasWidth) {
            left = (tooltip.caretX - tooltipEl.offsetWidth) + 'px';
          }

          if (ttpPosY > canvasHeight) {
            top = (tooltip.caretY - tooltipEl.offsetHeight) + 'px';
          }

          tooltipEl.style.left = left
          tooltipEl.style.top = top
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
          tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
          tooltipEl.style.padding =
            tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
          const tableEl = tooltipEl.querySelector('table');
          if (tableEl) {
            // Adjust the tooltip size according to the table's width
            const tableWidth = tableEl.offsetWidth;
            tooltipEl.style.width = (tableWidth + 10) + 'px';
          }
        },
        callbacks: {
          afterLabel: (tooltipItem, data) => {
            const canvasEl = this.chart.chartInstance.canvas;
            let toolTipLineEl = document.getElementById('chartjs-tooltip-line');
            if (!toolTipLineEl) {
              toolTipLineEl = document.createElement('div');
              toolTipLineEl.id = 'chartjs-tooltip-line';
              if (canvasEl && canvasEl.parentNode) {
                canvasEl.parentNode.appendChild(toolTipLineEl);
              }
            }
            toolTipLineEl.style.left = tooltipItem.x + 'px';
          }
        }
      },
      layout: {
        padding: {
          left: 30,
          right: 40,
          top: 30,
          bottom: 10
        }
      },
      maintainAspectRatio: !isMobile,
      scales: {
        yAxes,
        xAxes: [
          {
            type: 'time',
            // display: false,
            // distribution: 'series',
            position: 'top',
            time,
            ticks: {
              labelOffset: xAxesLabelOffset,
              fontSize: 14,
              fontFamily: 'Nunito',
              // autoSkip: true,
              padding: 10,
              fontColor: '#999'
            },
            gridLines: {
              color:
                workHoursFilter && workHoursFilter.length ? '#999' : '#efefef'
              // drawBorder: false
              // display: false
            }
          }
        ]
      },
      legendCallback: (chart: any) => {
        const text: string[] = [];
        text.push('<ul class="chartjs-legend">');
        chart.data.datasets.forEach((dataset: any, i: number) => {
          text.push('<li>');
          text.push('<span style="background-color:' + dataset.borderColor + '"></span>');
          text.push(dataset.label);
          text.push('</li>');
        });
        text.push('</ul>');
        return text.join('');
      }
    };
  };

  render() {
    const { isLegendDisplayed } = this.props;
    return (
      <div className='line-chart-wrapper'>
        {isLegendDisplayed && (
          <div id='chartjs-html-legend' className='chartjs-html-legend'></div>
        )}
        <div className='chart-container'>
          <Line
            ref={e => {
              if (e) {
                this.chart = e;
              }
            }}
            data={this.chartData}
            redraw={true}
            options={this.chartOptions()}
          />
        </div>
      </div>
    );
  }
}

export default LineChart;
